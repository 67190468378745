export const TeamMember = [
  {
    id: 1,
    name: "Rachana",
    designation: "CEO & Founder",
    image: "https://media.istockphoto.com/id/1009749608/photo/young-woman-portrait-in-the-city.jpg?s=612x612&w=0&k=20&c=nBmdXrCkI6Zz-J1EkCGQAamaYlZeIOQAtKunC4WMT-U=",
    twitter: "https://twitter.com/rachana_rajput",
    github: "",
    linkedin: "https://www.linkedin.com/in/rachana-rajput-0a8b3b1b3/",
    instagram: "https://www.instagram.com/rachana_rajput_/",
    facebook: "https://www.facebook.com/rachana.rajput.399",
  },
  {
    id: 3,
    name: "Suchi",
    designation: "CO Founder",
    image: "https://images.unsplash.com/photo-1631847988790-683ee6b22f4e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTR8fGJlYXV0aWZ1bCUyMGdpcmwlMjBwaG90b3xlbnwwfHwwfHw%3D&w=1000&q=80",
    twitter: "https://twitter.com/rachana_rajput",
    github: "",
    linkedin: "https://www.linkedin.com/in/rachana-rajput-0a8b3b1b3/",
    instagram: "https://www.instagram.com/rachana_rajput_/",
    facebook: "https://www.facebook.com/rachana.rajput.399",
  },
  {
    id: 2,
    name: "Pooja",
    designation: "Designer",
    image: "https://qph.cf2.quoracdn.net/main-qimg-9115fc72ac8c9eac6c05fbe90d126b63-lq",
    twitter: "https://twitter.com/rachana_rajput",
    github: "",
    linkedin: "https://www.linkedin.com/in/rachana-rajput-0a8b3b1b3/",
    instagram: "https://www.instagram.com/rachana_rajput_/",
    facebook: "https://www.facebook.com/rachana.rajput.399",
  },
  {
    id: 4,
    name: "Sanju",
    designation: "Web Designer",
    image: "https://img.freepik.com/free-photo/happiness-wellbeing-confidence-concept-cheerful-attractive-african-american-woman-curly-haircut-cross-arms-chest-self-assured-powerful-pose-smiling-determined-wear-yellow-sweater_176420-35063.jpg",
    twitter: "https://twitter.com/rachana_rajput",
    github: "",
    linkedin: "https://www.linkedin.com/in/rachana-rajput-0a8b3b1b3/",
    instagram: "https://www.instagram.com/rachana_rajput_/",
    facebook: "https://www.facebook.com/rachana.rajput.399",
  },
];
